import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import {
  ADD_PRODUCT,
  DECREMENT_QUANTITY,
  INCREMENT_QUANTITY,
  useProducts,
  useProductsDispatch,
} from '../store/index'
import { formatCurrencyString } from '../utils/stripe'

const Product = ({
  data: {
    stripePrice: { product, unit_amount: price, currency, id },
  },
}) => {
  const products = useProducts()
  const dispatch = useProductsDispatch()

  const inCart =
    products && products.length > 0
      ? products.find((item) => item.id === id)
      : null

  return (
    <Layout>
      <div className='container '>
        <div className='row productDetailTop'>
          <div className='col-md-8 col-sm-12'>Shop | Drinks | Item name</div>
          <div className='col-md-4 col-sm-12 previousNext d-flex'>
            Previous | Next
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='row'>
          <div className='col-sm-12 col-md-6'>
            <img
              className='img-fluid rounded'
              src={product.images}
              alt='Vegan, Gluten Free'
            />
          </div>
          <div className='col-sm-12 col-md-6 paddingLeftandTop'>
            <h2>{product.name}</h2>
            <strong className='text-green fs-3'>
              {formatCurrencyString({
                value: parseInt(price, 10),
                currency,
              })}
            </strong>
            <div className='mt-3'>{product.description}</div>
            <form className='row mt-5'>
              <div className='col-sm-8 '>
                <div className='mb-3'>
                  <label htmlFor='name' className='form-label fw-bold'>
                    Size:
                  </label>
                  <select
                    className='form-select'
                    aria-label='Default select example'
                  >
                    <option selected>Select size</option>
                    <option value='1'>One</option>
                    <option value='2'>Two</option>
                    <option value='3'>Three</option>
                  </select>
                </div>
              </div>
              <div className='col-sm-4 d-flex '>
                <div className='mb-3 flex-grow-1 d-flex flex-column'>
                  <label htmlFor='message' className='form-label fw-bold'>
                    Quantity:
                  </label>
                  <div className='input-group mb-3'>
                    <button
                      className='btn btn-light-grey'
                      type='button'
                      id='button-addon1'
                      onClick={() =>
                        dispatch({
                          type: DECREMENT_QUANTITY,
                          id,
                        })
                      }
                    >
                      -
                    </button>
                    <input
                      type='text'
                      className='form-control text-center'
                      aria-label='Example text with button addon'
                      aria-describedby='button-addon1'
                      value={inCart ? inCart.quantity : 0}
                    />
                    <button
                      className='btn btn-light-grey'
                      type='button'
                      id='button-addon1'
                      onClick={() =>
                        dispatch({
                          type: INCREMENT_QUANTITY,
                          id,
                        })
                      }
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <button
              type='button'
              disabled={inCart}
              className='btn btn-outline-dark w-100 mt-3 fw-bold'
              onClick={() => {
                dispatch({
                  type: ADD_PRODUCT,
                  id,
                  name: product.name,
                  price,
                })
              }}
            >
              Add to Basket
            </button>
            {inCart && (
              <Link
                className='btn btn-outline-dark w-100 mt-3 fw-bold'
                to='/cart'
              >
                Checkout
              </Link>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    stripePrice(fields: { slug: { eq: $slug } }) {
      unit_amount
      currency
      id
      product {
        name
        images
        description
      }
    }
  }
`

Product.propTypes = {
  data: PropTypes.shape({
    stripePrice: PropTypes.shape({
      id: PropTypes.string,
      product: PropTypes.string,
      unit_amount: PropTypes.number,
      currency: PropTypes.number,
    }),
  }).isRequired,
}

export default Product
