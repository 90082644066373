/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */
import { loadStripe } from '@stripe/stripe-js'

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)
  }
  return stripePromise
}

export const formatCurrencyString = ({ value, currency, language }) => {
  const numberFormat = new Intl.NumberFormat(language, {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
  })
  const parts = numberFormat.formatToParts(value)
  let zeroDecimalCurrency = true

  parts.forEach((part) => {
    if (part.type === 'decimal') {
      zeroDecimalCurrency = false
    }
  })

  return numberFormat.format(
    zeroDecimalCurrency ? value : parseFloat((value / 100).toFixed(2))
  )
}

export default getStripe
